<template>
  <div>

    <v-menu :close-on-content-click="false"
            offset-y
            ref="menu" >

      <!-- ACTIVATOR-->
      <template v-slot:activator="{ on, attrs }">
        <v-sheet  @click="displayEdit" color="transparent" v-bind="attrs"  v-on="on" >
          <h6 v-if="!admin" :style="value.video_url ? `color : ${wsACCENT}` : 'grey'" class="d-flex align-center " >
            <v-icon :color="value.video_url ? wsACCENT : 'grey'" class="mr-1" small>mdi-cast-education</v-icon>
            {{ $t('Link') }}
            <v-icon small>mdi-chevron-down</v-icon>
          </h6>
          <h5 v-if="admin" :style="value.video_url ? `color : ${wsACCENT}` : 'grey'" class="d-flex align-center " >
            <v-icon :color="value.video_url ? wsACCENT : 'grey'" class="mr-1" small>mdi-cast-education</v-icon>
            {{ $t('Link') }}
            <v-icon small>mdi-chevron-down</v-icon>
          </h5>
        </v-sheet>
      </template>


      <!-- MAIN CONTENT-->
      <v-sheet  class="pa-6 align-center"    width="400" >

        <div class="d-flex justify-space-between align-center">
          <h5 class="">{{ $t('VideoMeetingType') }}:</h5>
          <ft-select v-if="admin" v-model="entityData.video_type" :items="TASK_VIDEO_TYPES" >
            <template v-slot="{text}">
              <div class="d-flex">

                <h5>{{ text ? text : $t('Choose') }}</h5>

              </div>
            </template>
          </ft-select>
<!--          <h5 v-else>{{ video_type }}</h5>-->
        </div>

        <v-text-field v-model="entityData.video_url"
                      class="mt-6" style="font-size: 12px"
                      prepend-inner-icon="mdi-link"
                      :placeholder="$t('Link')"
                      hide-details
                      :readonly="!admin"
                      outlined
                      clearable
                      dense   />
        <v-text-field  v-model="entityData.video_password"
                       class="mt-1 " style="font-size: 12px"
                       prepend-inner-icon="mdi-form-textbox-password"
                       :placeholder="$t('Password')"
                       hide-details
                       :readonly="!admin"
                       outlined
                       clearable
                       dense   />

        <h5 class=" mt-6 mb-6">{{ $t('Time') }}:</h5>

        <wsTimePicker :placeholder="$t('Time')"  class="mt-6" :color="wsACCENT" v-model="entityData.video_time"></wsTimePicker>


        <div class="d-flex justify-end mt-6">

           <div v-if="admin">
            <v-btn text small @click="$refs.menu.save()" class="noCaps  " :color="wsDARK" dark>{{$t('Cancel')}}</v-btn>
            <v-btn small @click="saveChanges" class="noCaps  " :color="wsDARK" dark>{{ $t('Save') }}</v-btn>
          </div>
          <v-btn v-if="!admin" small :href="value.video_url" target="_blank" class="noCaps" :color="wsDARK" dark>
            {{ $t('Goto') }}
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>



        </div>


      </v-sheet>
    </v-menu>



  </div>
</template>

<script>
import {mapActions} from "vuex";
import wsTimePicker from "@/components/UI/wsTimePicker";

export default {
  name: "videoMeetingMenu",
  components : {
    wsTimePicker
  },
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    admin : {
      type : Boolean,
      default : false
    },
    enrollmentId : {
      type : String,
      default : ''
    }
  },
  data() {
    return  {
      entityData : {}
    }
  },
  methods : {
    ...mapActions('courses', [ 'EDIT_COMPONENT_SCHEDULE']),

    async saveChanges() {
      let data = await this.EDIT_COMPONENT_SCHEDULE(this.entityData)

      if (!data) {
        return this.notify("ConnectionError")
      }
      this.$emit('input',data)
      this.notify(this.$t("ChangesSaved"))
      this.$refs.menu.save()

    },
    displayEdit() {
      this.entityData = JSON.parse(JSON.stringify(this.value))
      this.entityData.enrollment_wave_id = this.enrollmentId
    },




  },
}
</script>

<style scoped>

</style>