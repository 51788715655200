<template>
  <div class="fill-height">
    <div v-if="!loading" class="fill-height">
      <div v-if="entity.modules.length > 0" class="fill-height">
        <!-- :: CONTENT :: -->
        <v-sheet v-for="(module,i) in entity.modules" :key="i + 'semester'" :ref="'block_' + i" class="wsRounded mt-3 pb-9">

          <!-- Semester Content-->
          <v-simple-table dense style="border-radius: 15px; !important  "  >

            <!-- Module Info -->
            <thead >
            <tr>
              <td class="py-2"
                  :style="`border-bottom: 1px solid ${wsBACKGROUND} `" >

                <div class="d-flex align-center justify-space-between">
                  <div class="d-flex align-center">
                    <h3 > {{ module.name }}</h3>
                  </div>

                  <wsModuleScheduleEdit v-model="entity.modules[i]"
                                        :enrollment-id="enrollmentId" />

                </div>
              </td>
            </tr>
            </thead>

            <!-- :: MODULE CONTENT :: -->
            <tbody>
            <template  v-for="(topic,j) in module.topics">
              <!-- Topic Name  -->
              <tr style="background: transparent" :key="j + 'topic_name'">
                <td  :style="`border-bottom: 1px solid ${wsBACKGROUND} `" class="py-3" colspan="7">
                  <h4>{{  topic.name  }}</h4>
                </td>
              </tr>

              <!-- COMPONENTS  -->
              <tr :key="j + 'components'" style="background-color: transparent;" >
                <!-- Components content  -->
                <td colspan="7" class="pt-3" style="border: none">
                  <table width="100%" class="componentsTable"    >
                    <!-- Components list  -->
                    <tbody>
                    <tr  v-for="(component, k) in topic.components" :key="'component' + i + j + k " style="cursor: pointer" >
                      <!--  Name -->
                      <td  class="py-1">
                        <div class="d-flex align-center">
                          <v-icon  :color="getEntityTypeIconColor(component)" class="mr-3 "> {{ getEntityTypeIcon(component.entity_type ) }}</v-icon>
                          <h4 >{{  component.name  }}</h4>
                        </div>
                      </td>
                      <td>
                        <wsVideoMeetingMenu  v-if="component.entity_type === 'task_video'"
                                             v-model="entity.modules[i].topics[j].components[k]"
                                             :enrollment-id="enrollmentId" admin />
                      </td>
                      <!-- Date -->
                      <td  width="70px" class="text-no-wrap  " align="right">
                        <v-sheet class="d-flex justify-end ">
                          <wsComponentScheduleEdit v-model="entity.modules[i].topics[j].components[k]"
                                                   :module-start="module.date_start"
                                                   :module-end="module.date_end"
                                                   :video="component.entity_type === 'task_video'"
                                                   :enrollment-id="enrollmentId" />
                        </v-sheet>
                      </td>

                    </tr>
                    </tbody>
                    <!-- Add Component button -->
                  </table>
                </td>
              </tr>

            </template>
            </tbody>
          </v-simple-table>

        </v-sheet>
      </div>
      <div v-else
           class="wsRoundedLight
         d-flex
         fill-height
         justify-center
         align-center
         pb-16 pt-8 py-6 " >

        <v-sheet  width="600">

          <div class="d-flex justify-center">
            <v-icon size="80" :color="wsDARKLIGHT" >mdi-calendar</v-icon>
          </div>
          <h3 style="font-size: 22px; font-weight: 700" class="text-center  my-6">{{ $t('NoContentMessage') }}</h3>
          <div class="d-flex justify-center">
            <v-btn height="40" :to="businessDashLink('courses/' + this.uuid + '/editor')" dark :color="wsATTENTION" class="noCaps">
              {{ $t('CourseEditor') }}
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </v-sheet>

      </div>
    </div>
    <v-sheet v-else class="fill-height d-flex align-center justify-center">
      <v-progress-circular indeterminate :color="wsACCENT" />
    </v-sheet>

  </div>
</template>

<script>

import {mapActions, mapMutations} from "vuex";
import wsModuleScheduleEdit from "@/components/pages/westudy/admin/course/UI/wsModuleScheduleEdit";
import wsComponentScheduleEdit
  from "@/components/pages/westudy/admin/course/UI/wsComponentScheduleEdit";
import wsVideoMeetingMenu from "@/components/pages/westudy/admin/course/UI/wsVideoMeetingMenu";

export default {
  name: "PageCourses",
  props : ['uuid','enrollmentId'],
  components : {
    wsModuleScheduleEdit,
    wsComponentScheduleEdit,
    wsVideoMeetingMenu
  },
  data() {
    return {
      loading : false,

      uploadLogo : false,
      logoUrl : null,

      items : [],

      selectedTopic : null,
      selectedModule : null,

      editNameModule : false,

      newEntity  : true,
      displayDialog : false,
      displayTopicDialog : false,
      displayModuleDialog : false,
      entity : {
        name : "",
        components : [],
        modules : []
      },
      entityData : {
        name : {},
      },
      entityDataDefault : {
        name : { },
        alias : ''
      },
      defaultComponent : {
        name : { },
        topic_id : null,
      },

      fileUploaded : false,
      selectedFile : {},
      redirect : false,
    }
  },
  computed : {



    showEntityTypesSelectCondition() {
      return !( this.entityData.file || this.entityData.lecture || this.entityData.task  )
    },

    entityTypes() {
      return [
        { text : this.$t('None'), value: null },
        { text : this.$t('Lecture'), value: 'lecture' },
        { text : this.$t('Task'), value: 'task' },
        { text : this.$t('File'), value: 'file' },
      ]
    },

    actionTypes() {
      return [
        { text : this.$t('CreateNew')  , value: 'new'     }
      ]
    },

    saveText() {
      return this.newEntity ? this.$t('Add') : this.$t('Save')
    }
  },
  methods : {
    ...mapActions('courses', [
        'GET_COURSE_SCHEDULE',
        'GET_COMPONENT'
    ]),
    ...mapMutations('courses',[ 'INIT_PAGE']),

    async displayComponentEdit(uuid) {
      this.newEntity = false
      this.entityData = JSON.parse(JSON.stringify(await this.GET_COMPONENT(uuid) || this.entityDataDefault))
      this.displayDialog = true
    },

    async editComponent() {
      let data = await this.EDIT_COMPONENT(this.entityData)

      if (!data) {
        return this.notify("ConnectionError")
      }

      let moduleIndex = this.entity.modules.findIndex( el=> el.uuid === data.module_id)
      if (moduleIndex === -1 ) { return this.notify('Error 1')  }

      let topicIndex = this.entity.modules[moduleIndex].topics.findIndex( el=> el.uuid === data.topic_id)
      if (topicIndex === -1 ) { return this.notify('Error 2')  }

      let componentIndex = this.entity.modules[moduleIndex].topics[topicIndex].components.findIndex( el=> el.uuid === data.uuid)
      if (componentIndex === -1 ) { return this.notify('Error 3')  }

      this.entity.modules[moduleIndex].topics[topicIndex].components[componentIndex] = data
      this.notify(this.$t("ChangesSaved"))

      this.displayDialog = false

    },

    // Technical
    getEntityTypeIcon( type ) {

      let icon = ''
      switch ( type ) {
        case 'lecture'    :  icon = 'mdi-book-open'       ; break;
        case 'task'       :  icon = 'mdi-content-paste'                ; break;
        case 'task_video' :  icon = 'mdi-cast-education'               ; break;
        case 'test'       :  icon = 'mdi-order-bool-ascending-variant' ; break;
        case 'task_test'  :  icon = 'mdi-order-bool-ascending-variant' ; break;
        case 'video'      :  icon = 'mdi-video'                        ; break;
        case 'file'       :  icon = 'mdi-file'                         ; break;
        default : icon = 'mdi-bookmark'
      }
      return icon

    },
    getEntityTypeIconColor(  ) {

      return  this.wsATTENTION

    },
    scrollToModule(index) {
      this.$vuetify.goTo(this.$refs['module_' + index][0],
          {
            duration: 300,
            offset: 70,
            easing: 'easeInOutQuad',
          })
    },



    flushEntity() {
      this.entityData = JSON.parse(JSON.stringify(this.entityDataDefault))
    },

    // Page init
    async initPage(){
      this.flushEntity()

      let data = {
        uuid : this.uuid,
        enrollmentId : this.enrollmentId
      }
      let result =  await this.GET_COURSE_SCHEDULE(data) ;
      if ( !result || result === true ) {
        this.loading = false
        return
      }

      result.modules.forEach(module => {
        module.topics = module.topics.filter( el=>el.components.length > 0 )
      })
      result.modules = result.modules.filter( el => el.topics.length > 0)

      this.entity = result

      this.INIT_PAGE({
        icon : 'mdi-chevron-left',
        icon_route : this.businessDashLink('courses/' + this.uuid + '/calendar'),
        title : this.$t('Schedule') + ': ' + this.entity.schedule_name,
      })

      this.$store.state.courses.modules = this.entity.modules
      this.loading = false
    }
  },

  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>