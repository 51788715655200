<template>

  <v-menu
      ref="menu"
      :close-on-content-click="false"
      left
      transition="fade-transition"
      offset-y >

    <template v-slot:activator="{ on, attrs }">
      <v-sheet  @click="displayEdit"  v-bind="attrs" v-on="on">

        <div v-if="!offline">
          <div v-if="value.date_start || value.date_end" class="d-flex align-center justify-end">
            <h5  v-if="video && value.video_time" class=" mr-3" >
              <v-icon size="18" :color="wsDARK" class="mr-1"> mdi-clock </v-icon>
              {{ value.video_time }}
            </h5>
            <v-icon size="18" :color="wsDARK" class="mr-1"> mdi-calendar </v-icon>
            <h5 class=" d-flex justify-end" >
              {{ value.date_start ? value.date_start : $t('FromBeginning') }}
              <span v-if="!video" class="mx-1">{{ '-' }}</span>
              <span v-if="!video">{{ value.date_end ? value.date_end : $t('TillTheEnd') }}</span>
            </h5>
            <v-icon size="18" :color="wsDARK" class="mr-1"> mdi-chevron-down </v-icon>
          </div>
          <div v-else class="d-flex justify-end align-center">
            <v-icon size="18" :color="wsDARK" class="mr-1"> {{ !video ? 'mdi-eye' : 'mdi-calendar' }} </v-icon>

            <h5 v-if="!video" class="" >
              {{ moduleStart || moduleEnd ? $t('AccordingToModule') :  $t('AccessAlways')  }}
            </h5>
            <h5 v-else class="" > {{ $t('Choose') }} </h5>
            <v-icon size="18" :color="wsDARK" class="mr-1"> mdi-chevron-down </v-icon>
          </div>
        </div>
        <div v-else>
          <div v-if="value.date_start || value.date_end" class="d-flex align-center">
            <v-icon size="18" :color="wsDARK" class="mr-1"> mdi-calendar </v-icon>
            <h5>
              {{ value.date_start ? ($t('Day_') + ' ' + value.date_start) : $t('FromBeginning') }}
              -
              {{ value.date_end   ? ($t('Day_') + ' ' + value.date_end) : $t('TillTheEnd') }}
            </h5>
            <v-icon size="18" :color="wsDARK" > mdi-chevron-down </v-icon>
          </div>
          <div v-else class="d-flex justify-end align-center">
            <v-icon size="18" :color="wsDARK" class="mr-1"> {{ !video ? 'mdi-eye' : 'mdi-calendar' }} </v-icon>

            <h5 v-if="!video" class="" >
              {{ moduleStart || moduleEnd ? $t('AccordingToModule') :  $t('AccessAlways')  }}
            </h5>
            <h5 v-else class="" > {{ $t('Choose') }} </h5>
            <v-icon size="18" :color="wsDARK" > mdi-chevron-down </v-icon>
          </div>
        </div>





      </v-sheet>
    </template>

    <v-sheet  class="pa-6" width="400">

      <div class="d-flex justify-space-between align-center mb-6">
        <h5 class="">{{ $t('ComponentSchedule') }}:</h5>
        <v-btn @click="$refs.menu.save()" icon small ><v-icon>mdi-close</v-icon></v-btn>
      </div>

      <ws-date-picker
          v-if="!offline"
          v-model="entityData.date_start"
          :label="$t('DateStart')"
          :placeholder="$t('DateStart')"
          :clearable="true"/>
      <ws-text-field
          v-else
          v-model="entityData.date_start"
          :label="$t('DayStart')"
          :placeholder="$t('DayStart')"
          number number-length="4"
          clearable
      />
      <br>
      <div v-if="!offline">
        <ws-date-picker
            v-if="!video"
            v-model="entityData.date_end"
            :label="$t('DateEnd')"
            :placeholder="$t('DateEnd')"
            :clearable="true" />
      </div>

      <ws-text-field
          v-else
          v-model="entityData.date_end"
          :label="$t('DateEnd')"
          :placeholder="$t('DateEnd')"
          number number-length="4"
          clearable
      />

      <div class="d-flex justify-end mt-6">

        <v-btn  @click="$refs.menu.save()" class="noCaps mr-2" :color="wsACCENT" outlined>{{$t('Cancel')}}</v-btn>
        <v-btn  @click="saveChanges" class="noCaps" :color="wsATTENTION" dark>{{ $t('Save') }}</v-btn>

      </div>

    </v-sheet>

  </v-menu>




</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "wsComponentScheduleEdit",
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    enrollmentId : {
      type : String,
      default : ''
    },
    video : {
      type : Boolean,
      default : false,
    },
    moduleStart : {
      type : String,
      default : null
    },
    moduleEnd : {
      type : String,
      default : null
    },
    offline : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      entityData : { }
    }
  },

  methods : {
    ...mapActions('courses', [ 'EDIT_COMPONENT_SCHEDULE' , 'EDIT_COMPONENT']),
    async saveChanges() {
      if ( !this.offline ) {
        let data = await this.EDIT_COMPONENT_SCHEDULE(this.entityData)

        if (!data) {
          return this.notify("ConnectionError")
        }
        this.$emit('input',data)

      } else {
        this.editComponent()
      }

      this.$refs.menu.save()

    },
    async editComponent() {

      let data = await this.EDIT_COMPONENT(this.entityData)

      if (!data) {
        return this.notify(this.$t('Error'))
      }
      let component = JSON.parse(JSON.stringify(this.value))
      component.date_start = this.entityData.date_start
      component.date_end = this.entityData.date_end
      this.$emit('input',component)

    },
    displayEdit() {

      if ( !this.offline ) {
        this.entityData = JSON.parse(JSON.stringify(this.value))
        this.entityData.enrollment_wave_id = this.enrollmentId
      } else {
        this.entityData = {
          uuid : this.value.uuid,
          date_start : this.value.date_start,
          date_end : this.value.date_end,
          lang : this.$store.state.courses.selectedLang
        }
      }

    }
  }
}
</script>

<style scoped>

</style>